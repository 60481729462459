import React from 'react'

import moment from '../../../../assets/javascripts/moment-range'
import { colorOrderStatus } from '../../../../assets/javascripts/helper'

import TableComponent from '../../../../components/table/table.component'
import LabelComponent from '../../../../components/label/label.component'
import LinkComponent from '../../../../components/link/link.component'
import TextComponent from '../../../../components/text/text.component'
import { ContentLoaderTableOnly, ContentLoaderTableContentOnly } from '../../../../components/loader/loader.component'

const OrderList = ({ isBusy, orders, loading, networkStatus, evOpenPopup }) => {
  let sum
  if(orders) sum = parseInt(orders.totalCount)
  return (
    ((loading && !orders) || networkStatus === 2) ? <ContentLoaderTableOnly />
    : (sum ?  
      <div>
        <TableComponent mode='custom' template='2fr 1fr 1.5fr 1.5fr 2fr 1fr 2fr 2fr 1fr'>
          <div aria-label='head'>
            <div>Order Date</div>
            <div>Order ID</div>
            <div>Channel Order ID</div>
            <div>Merchant</div>
            <div>Customer Name</div>
            <div>Courier</div>
            <div>AWB</div>
            <div>Status</div> 
            <div>Action</div>
          </div>
          <div aria-label='body'>
            {orders.edges.map((order, idx) => {
              return (
                <div key={idx}>
                  <div>
                    <LabelComponent mode='tagredmobile'>Order Date</LabelComponent>
                    {moment(order.node.orderedAt, 'YYYY-MM-DD HH:mm:ss ZZ').format('DD/MM/YYYY - h:mm a')}
                  </div>
                  <div>
                    <LabelComponent mode='tagredmobile'>Order ID</LabelComponent>
                    {order.node.id}
                  </div>
                  <div>
                    <LabelComponent mode='tagredmobile'>Channel Order ID</LabelComponent>
                    {order.node.localId}
                  </div>
                  <div>
                    <LabelComponent mode='tagredmobile'>Merchant</LabelComponent>
                    {order.node.profileName}
                  </div>
                  <div>
                    <LabelComponent mode='tagredmobile'>Customer Name</LabelComponent>
                    {order.node.customer ? order.node.customer.name : ''}
                  </div>
                  <div>
                    <LabelComponent mode='tagredmobile'>Courier</LabelComponent>
                    {order.node.couriers.join(', ')}
                  </div>
                  <div>
                    <LabelComponent mode='tagredmobile'>AWB</LabelComponent>
                    <TextComponent color='blue' weight='600'>
                      {(order.node.awb) ? order.node.awb : '-'}
                    </TextComponent>
                  </div>
                  <div>
                    <LabelComponent mode='tagredmobile'>Status</LabelComponent>
                    <TextComponent color={colorOrderStatus(order.node.statuses[0])} weight='600'>{order.node.statuses[0]}</TextComponent>
                  </div>
                  <div>
                    <LabelComponent mode='tagredmobile'>Action</LabelComponent>
                    <TextComponent weight='600'>
                      <LinkComponent id={'link-view-detail-'+order.node.id} to='#' mode='clearlink' data-popup='detail-order' data-id={order.node.id} onClick={evOpenPopup}>View Detail</LinkComponent>
                    </TextComponent>
                  </div>
                </div>
              )
            })}
          </div> 
        </TableComponent>
        { isBusy ? <ContentLoaderTableContentOnly /> : null }
      </div>
      : <LabelComponent mode='empty' font-size='14px'>There is no Order</LabelComponent> )
  )
}

export default OrderList;