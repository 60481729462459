import React, { Component } from 'react'
import Cookies from 'universal-cookie'
import Auth from '../login/auth/auth'

import EmptyLayout from '../../_layouts/empty-layout'

import HeadingComponent from '../../components/heading/heading.component'
import LabelComponent from '../../components/label/label.component'
import InputComponent from '../../components/input/input.component'
import CheckboxComponent from '../../components/checkbox/checkbox.component'
import LinkComponent from '../../components/link/link.component'

import { BackgroundContainer, LoginContainer, SideContainer, LogoContainer, LoginFormContainer, RememberContainer, CopyContainer, ErrorContainer } from './login.styles'

const cookies = new Cookies()

class LoginIndex extends Component {
  constructor(props){
    super(props);
    this.state = {
      email: '',
      password: '',
      errorMessage: '',
      remember: false
    }
    this.date = new Date()
  }

  componentDidMount() {
    if(cookies.get('channel_token')){
      Auth.login(() => {
        this.props.history.push('/dashboard/orders')
      });
    } else {
      this.props.history.push('/');
    }
  }

  handleChangeInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    fetch('https://user.forstok.com/channel_user/login', {
      method: 'POST',
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json()).then(res => {
      if(res.success) {
        let _expired
        if(!this.state.remember) {
          _expired = new Date(res.data.channel_user.token_expired)
        }

        if(process.env.NODE_ENV === 'development') {
          cookies.remove('channel_token',{ path: '/' })
          cookies.set('channel_token', res.data.channel_user.token, { path: '/', expires: _expired})
        }else {
          cookies.remove('channel_token',{ path: '/', domain:'.forstok.com' })
          cookies.set('channel_token', res.data.channel_user.token, { path: '/', domain:'.forstok.com', expires: _expired})
        }
      
        Auth.login(() => {
          this.props.history.push('/dashboard/orders')
        });
      } else {
        this.setState({ errorMessage: res.data.base[0] })
      }
    })
  }

  handleChangeCheckbox = e => {
    this.setState({ remember: e.target.checked })
  }

  render() {
    return (
      <EmptyLayout>
        <BackgroundContainer>
          <LoginContainer>
            <SideContainer>
              <LogoContainer></LogoContainer>
            </SideContainer>
            <LoginFormContainer onSubmit={this.handleSubmit}>
              <HeadingComponent type="h1">
                Login
              </HeadingComponent>
                <ErrorContainer>{this.state.errorMessage}</ErrorContainer> 
              <LabelComponent mode="block">
                Email
              </LabelComponent>
              <InputComponent id='input-email' type="email" mode="large" name="email" value={this.state.email} onChange={this.handleChangeInput} required/>
              <LabelComponent mode="block">
                Password
              </LabelComponent>
              <InputComponent id='input-password' type="password" mode="large" name="password" value={this.state.password} onChange={this.handleChangeInput} required/>
              <RememberContainer>
                <CheckboxComponent id='check-remember' name="remember" mode="form" handleChange={this.handleChangeCheckbox}>Remember</CheckboxComponent>
                <LinkComponent id='link-forgot' mode='clearlink' to='/'>Forgot?</LinkComponent>
              </RememberContainer>
              <InputComponent id='button-login' type="submit" mode="large" value="Login"/>
            </LoginFormContainer>
            <CopyContainer>
              © {this.date.getFullYear()} PT. Forstok. All Rights Reserved.
              <LinkComponent mode='clearlink' to='mailto:sales@forstok.com'>Contact Us</LinkComponent>
            </CopyContainer>
          </LoginContainer>
        </BackgroundContainer>
      </EmptyLayout>
    )
  }
}

export default LoginIndex;