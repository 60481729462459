import styled, { css } from 'styled-components'

/* style */
const storeStyles = css `
  width: 32px;
  height: 32px;
`
const productStyles = css`
  background-color: #e9ebec;
`
/* style */

/* function */
const getImageModifiedStyled = ({mode}) => {
  if(mode === 'store') return storeStyles
  else if(mode === 'product') return productStyles
}
const getOtherImageModifiedStyled = (props) => {
  let stylesTab = ''
  if(props['width']) stylesTab += `
    width:${props['width']}; 
    height:${props['width']};
  `
  return stylesTab
}
/* function */

/* component */
export const ImageContainer = styled.img`
  ${getImageModifiedStyled}
  ${getOtherImageModifiedStyled}
`
/* component */