import React from 'react'

import TableComponent from '../../../../components/table/table.component'
import LabelComponent from '../../../../components/label/label.component'
import LinkComponent from '../../../../components/link/link.component'
import TextComponent from '../../../../components/text/text.component'
import { ContentLoaderTableOnly, ContentLoaderTableContentOnly } from '../../../../components/loader/loader.component'

export const ReportingList = ({ isBusy, reports, loading, networkStatus, handleDownload }) => {
  let sum
  if(reports) sum = parseInt(reports.totalCount)
  
  return (
    ((loading && !reports) || networkStatus === 2) ? <ContentLoaderTableOnly />
    : (sum ?  
      <div>
        <TableComponent mode='custom' template='5fr 1fr 2fr 2fr'>
          <div aria-label='head'>
            <div>Merchant</div>
            <div># orders</div>
            <div>Revenue</div>
            <div>Action</div>
          </div>
          <div aria-label='body'>
            {reports.edges.map((report, idx) => {
              return (
                <div key={idx}>
                  <div>
                    <LabelComponent mode='tagredmobile'>Merchant</LabelComponent>
                    {report.node.profileName}
                  </div>
                  <div>
                    <LabelComponent mode='tagredmobile'># orders</LabelComponent>
                    {report.node.ordersCount}
                  </div>
                  <div>
                    <LabelComponent mode='tagredmobile'>Revenue</LabelComponent>
                    <TextComponent>{report.node.revenue}</TextComponent>
                  </div>
                  <div>
                    <LabelComponent mode='tagredmobile'>Action</LabelComponent>
                    <TextComponent weight='600'>
                      <LinkComponent id={'link-download-so-'+report.node.accountId} to='' mode='clearlink' onClick={(e) => handleDownload(e, {stores: ''+report.node.accountId+''})}>Download SO</LinkComponent>
                    </TextComponent>
                  </div>
                </div>
              )
            })}
          </div>
        </TableComponent>
        { isBusy ? <ContentLoaderTableContentOnly /> : null }
      </div>
      : <LabelComponent mode='empty' font-size='14px'>There is no Report</LabelComponent> )
  )
}