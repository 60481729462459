import React from 'react'

import { CheckboxContainer, InputContainer, CheckmarkContainer } from '../checkbox/checkbox.styles.jsx'

const CheckboxComponent = ({ name, children, handleChange, mode, value, flow, checked, ...props }) => {
  return (
    <CheckboxContainer mode={mode} flow={flow} {...props}>
      {children}
      <InputContainer type='checkbox' className='checkbox' defaultChecked={checked} name={name} value={value} onChange={handleChange}/>
      <CheckmarkContainer mode={mode} isError={props.isError}></CheckmarkContainer>
    </CheckboxContainer>
  )
}

export default CheckboxComponent