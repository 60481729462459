import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Cookies from 'universal-cookie'
import Auth from './auth'

const cookies = new Cookies()

export class ProtectedRoute extends React.Component { 
  constructor(props) {
    super(props)
    this.state = {
      Authenticated: true
    }
  }

  async componentDidMount() {
    try {
      if(Auth.isAuthenticated()) {
        let authenticaty = false
        const response = await fetch('https://user.forstok.com/channel_user', {
          method: 'GET',
          headers: {
            'authorization': `Bearer ${cookies.get('channel_token')}`,
            'Content-Type': 'application/json',
          }
        })
        const result = await response.json()
        authenticaty = (result.success) ? true : false
        if(!authenticaty) {
          if(process.env.NODE_ENV === 'development') {
            cookies.remove('channel_token',{ path: '/' })
          }else {
            cookies.remove('channel_token',{ path: '/', domain:'.forstok.com' })
          }
        }
        this.setState({ Authenticated: authenticaty })
      } else {
        this.setState({ Authenticated: false })
      }
    } catch(err) {
      this.setState({ Authenticated: false })
    }
  }

  render() {
    const { Authenticated } = this.state
    const { component: Component, ability, as, ...rest } = this.props
    return (
      <Route 
        {...rest}
        render={props => {
          if(Authenticated){
            return <Component {...props}/>
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/',
                  state: {
                    from: props.location
                  }
                }}
              />
            )
          }
        }}
      />
    )
  }
}