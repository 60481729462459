import styled from 'styled-components'

export const SingleLayoutContainer = styled.section`
  display: grid;
  grid-template-areas:  'header'
                    'content';
  font: 400 88%/1.5em Proxima Nova,sans-serif;
  letter-spacing: normal;
  line-height: normal;
  color: #2c2c2c;
`
export const SingleLayoutBody = styled.section`
  display: grid;
  background-color: #f7f7f7;
  margin-top: 60px;
  min-height: calc(100vh - 60px);
`
export const SingleLayoutWrapper = styled.article`
  padding: 5px 5px;
  width: 100%;
`