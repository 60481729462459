import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ProtectedRoute } from './pages/login/auth/protected.route';
import LoginIndex from './pages/login/index'
import OrdersIndex from './pages/orders/index'
import ReportingIndex from './pages/reporting/index'

function App() {
  return (
    <BrowserRouter basename={'/'}>
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={LoginIndex}/>
        <ProtectedRoute path={`${process.env.PUBLIC_URL}/dashboard/orders`} component={OrdersIndex}/>
        <ProtectedRoute path={`${process.env.PUBLIC_URL}/dashboard/reporting`} component={ReportingIndex}/>
      </Switch>
    </BrowserRouter>
  )
}

export default App