import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from 'react-apollo'
import { client } from '../../_includes/forstok'

import SingleLayout from '../../_layouts/single-layout'
import MessageComponent from '../../components/message/message.component'

import ReportingPartial from './partials/reporting'

const evCreateMessage = props => {
  const bodyEl = document.getElementsByTagName('BODY')[0],
      messageEl = document.getElementById('message')

  if(!messageEl) {
    const el = document.createElement('div')
    el.id = 'message'
    bodyEl.append(el)
  }
  ReactDOM.render(<MessageComponent {...props} />, document.getElementById('message'))
}

const ReportingIndex = () => {
  return (
    <ApolloProvider client={client}>
      <SingleLayout>
        <ReportingPartial evCreateMessage={evCreateMessage}/>
      </SingleLayout>
    </ApolloProvider>
  )
}

export default ReportingIndex;