import styled from 'styled-components'
import { Link } from 'react-router-dom'

/* style */

/* style */

/* function */
const getMenuModifiedStyled = ({isactive, mode}) => {
  let stylesTab = ``
  if(isactive) stylesTab += `
    color: #fc5c64;
    font-weight: 700;

    &::after {
      content: '';
      position: absolute;
      background-color: #fc5c64;
      left: 0;
      height: 2px;
      bottom: -1px;
      right: 0;
      width: auto;
    }
  `
  return stylesTab
}
/* function */

/* component */
export const ReportingContainer = styled.div`
  width: 100%;
  height: 100%;
`
export const NavContainer = styled.div`
  display: inline-grid;
  margin: 0;
  padding: 0;
  grid-auto-flow: column;
`
export const LinkContainer = styled(Link)`
  position: relative;
  padding: 15px 30px;
  font-weight: 400;
  color: #2c2c2c;
  text-align: center;
  list-style: none;
  cursor: pointer;
  user-select: none;
  font-size: 20px;
  text-decoration: none;
  ${getMenuModifiedStyled}
`
export const LayoutNavContainer = styled.div`
  width: 100%;
  background-color: #fff;
`
export const LayoutContentContainer = styled.div`
  margin-top: 5px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  overflow: auto;
  height: calc(100vh - 120px);
`
export const FilterContainer = styled.div`
  display: grid;
  margin: 10px 0 20px;
  grid-auto-flow: column;
  grid-template-columns: 305px 200px 200px 300px;
  grid-gap: 10px;
`
export const FilterLabelWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 10px;
`
export const FilterLabel = styled.div`
  font-weight: 600;
`
export const FilterDownloadWrapper = styled.div`
  margin-left: 10px;
  display: grid;
  align-items: center;
`
/* component */