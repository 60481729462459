import Cookies from 'universal-cookie'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-boost'
import { setContext } from 'apollo-link-context'

export const forstokCookies = new Cookies()
const forstokHttpLink = createHttpLink({uri: 'https://graphql-channel.forstok.com/graphql'})
const forstokAuthLink = setContext((_, { headers }) => {
  const forstokToken = forstokCookies.get('channel_token')
  return {
    headers: {
      ...headers,
      authorization: forstokToken ? `Bearer ${forstokToken}` : "",
    }
  }
})
export const client = new ApolloClient({
  link: forstokAuthLink.concat(forstokHttpLink),
  cache: new InMemoryCache()
})