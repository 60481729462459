import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { debounce } from '../../../assets/javascripts/function'
import moment from '../../../assets/javascripts/moment-range'

import DateRangeComponent from '../../../components/date/daterange.component'
import IconComponent from '../../../components/icon/icon.component'
import InputComponent from '../../../components/input/input.component'

import OrderList from './list/order'
import { FilterMerchantList } from './filter/merchant'
import { FilterStatusList } from './filter/status'
import { OrderContainer, NavContainer, LinkContainer, LayoutNavContainer, LayoutContentContainer, FilterContainer, FilterLabelWrapper, FilterLabel, FilterSearchContainer } from '../orders.styles'
import { GET_ORDER_LIST } from '../../../config/graphql'

const OrderPartial = ({ evOpenPopup }) => {
  let location = useLocation()
  const [ isBusy, setBusy ] = useState(false)
  const [ startDate, setStartDate ] = useState(moment().add(-29, 'days'))
  const [ endDate, setEndDate ] = useState(moment())
  const [ rangeDate, setRangeDate ] = useState('lastmonth')
  const [ filterBy, setFilterBy ] = useState({})
  const [ searchInput, setSearchInput ] = useState('')

  const { loading, data, fetchMore, networkStatus } = useQuery(GET_ORDER_LIST, 
    { 
      variables: 
      { 
        first: 24, 
        filterBy: filterBy,
        search: searchInput,
        startDate: startDate,
        endDate: endDate
      },
      notifyOnNetworkStatusChange: true 
    }
  )

  const delayedCallback = debounce((e) => {
    let val = e.target.value
    setSearchInput(val)
  }, 300)

  const handleSearchInput = (e) => {
    e.persist()
    delayedCallback(e)
  }

  const evModDate = (startDate, endDate, rangeDate, callback) => {
    setStartDate(startDate)
    setEndDate(endDate)
    setRangeDate(rangeDate)
    if (callback && typeof(callback) == 'function') callback()
  }

  const handleChangeFilter = (e) => {
    let _finalFilter = {...filterBy}
    
    if(e.value !== '') 
    _finalFilter[e.type] = e.value
    else
    delete _finalFilter[e.type]

    setFilterBy(_finalFilter)
  }

  const handleScroll = debounce((currentTarget, onLoadMore, isBusy, pageInfo) => {
    if(isBusy || !pageInfo.hasNextPage ) return false
    if(currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight) onLoadMore()
  }, 250)

  const onLoadMore = () => {
    if(isBusy) return false
    setBusy(true)
    fetchMore({
      variables: {
        cursor: data.orders.pageInfo.endCursor
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if(!fetchMoreResult) return prev
        const newEdges = fetchMoreResult.orders.edges
        const pageInfo = fetchMoreResult.orders.pageInfo
        setBusy(false)
        const newData = newEdges.length
          ? {
              orders: {
                totalPageCount: prev.orders.totalPageCount,
                totalCount: prev.orders.totalCount,
                __typename: prev.orders.__typename,
                edges: [...prev.orders.edges, ...newEdges],
                pageInfo
              }
            }
          : prev
        return newData
      }
    })
  }

  return (
    <OrderContainer>
      <LayoutNavContainer>
        <NavContainer>
          <LinkContainer to='/dashboard/orders' isactive={location.pathname === '/dashboard/orders' ? 1 : 0}>Orders</LinkContainer>
          <LinkContainer to='/dashboard/reporting' isactive={location.pathname === '/dashboard/reporting' ? 1 : 0}>Reporting</LinkContainer>
        </NavContainer>
      </LayoutNavContainer>
      <LayoutContentContainer onScroll={(e) => handleScroll(e.currentTarget, onLoadMore, isBusy, data.orders.pageInfo)}>
        <FilterContainer>
          <FilterLabelWrapper>
            <FilterLabel>Date Range</FilterLabel>
            <DateRangeComponent id='select-date-range' StartDate={startDate} EndDate={endDate} RangeDate={rangeDate} evModDate={evModDate}/>
          </FilterLabelWrapper>
          <FilterLabelWrapper>
            <FilterLabel>Merchant</FilterLabel>
            <FilterMerchantList id='select-merchant' handleChange={handleChangeFilter}/>
          </FilterLabelWrapper>
          <FilterLabelWrapper>
            <FilterLabel>Order Status</FilterLabel>
            <FilterStatusList id='select-status' handleChange={handleChangeFilter}/>
          </FilterLabelWrapper>
          <FilterSearchContainer>
            <IconComponent name='search' width='16px'/>
            <InputComponent id='input-search' type='text' name='search' placeholder='Search Order ID, Customer Name' iconLeft='true' onChange={handleSearchInput}/>
          </FilterSearchContainer>
        </FilterContainer>
        <OrderList
          loading={loading}
          orders={data && data.orders}
          networkStatus={networkStatus}
          isBusy={isBusy}
          evOpenPopup={evOpenPopup}
        />
      </LayoutContentContainer>
    </OrderContainer>
  )
}

export default OrderPartial