import React from 'react'

import { TextContainer } from '../text/text.styles'

const TextComponent = ({ children, mode, color, weight, transform, decoration, ...props }) => {
  return (
    <TextContainer mode={mode} color={color} weight={weight} transform={transform} decoration={decoration}>
      {children}
    </TextContainer>
  )
}

export default TextComponent