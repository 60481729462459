import styled, { css } from 'styled-components'

/* style */
const GlobalCheckboxStyles = css`
  grid-gap: 6px;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  user-select: none;
  align-items: center;

  input:checked ~ span {
    background-color: #3e85f7;
  }
  input:checked ~ span:after {
    display: grid;
  }
`;
const BigDefaultStyled = css`
  font-size: 24px
  font-weight: 300;
`
const BigDefaultCheckmarkStyled = css`
  width: 16px;
  height: 16px;

  &:after {
    left: 4px;
    top: 0;
    width: 4px;
    height: 8px;
  }
`
const getInputContainerStyled = css`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`
const getCheckmarkContainerStyled = css`
  position: absolute;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 3px;
    top: -1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    border-radius: 4px;
  }
`
const arrowIconStyles = css`
  padding-right: 18px;

  i {
    position: absolute;
    top: 2px;
    right: 8px;
  }
`
/* style */

/* function */
const getCheckboxModifiedStyled = ({ mode, flow, iconRight }) => {
  if(mode === 'bigdefault') return BigDefaultStyled
  if(iconRight) return arrowIconStyles
  
  switch(flow){
    case 'column':
      return `display: inline-grid; grid-auto-flow: column;`
    default:
      return `display: grid; grid-auto-flow: row;`
  }
}
const getCheckmarkModifiedStyled = ({mode}) => {
  if(mode === 'bigdefault') return BigDefaultCheckmarkStyled
}
const getCheckmarkPropsModifiedStyled = props => {
  let modifiedStyled = ``
  if(props.isError) {
    modifiedStyled += ` border:1px solid #fc5c64; `
  }
  return modifiedStyled
}
/* function */

/* component */
export const CheckboxContainer = styled.label`
  ${GlobalCheckboxStyles}
  ${getCheckboxModifiedStyled}
`;
export const InputContainer = styled.input`
  ${getInputContainerStyled}
`
export const CheckmarkContainer = styled.span`
  ${getCheckmarkContainerStyled}
  ${getCheckmarkModifiedStyled}
  ${getCheckmarkPropsModifiedStyled}
`
/* component */