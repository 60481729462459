import styled from 'styled-components'
import IconSearch from '../../assets/images/icons/search.svg'

/* style */
/* style */

/* function */
const getIconContainerStyled = props => {
  let stylesIcon = ''
  switch(props['name']){
    case 'search' 
      : stylesIcon += `&:before { content: url(${IconSearch}); }`
      break;
    default
      : break;
  }

  if(props['width']) stylesIcon += `
    &:before { 
      width: ${props['width']}; 
      height: ${props['width']};
    }
  `
  
  if(props['onClick']) stylesIcon += `
    cursor: pointer;
  `
  return stylesIcon
}
/* function */

/* component */
export const IconContainer = styled.i`
  ${getIconContainerStyled}
  &:before {
    display: grid;
  }
`
/* component */