import React from 'react'

import { LoadingContainer, LoadingParentContainer } from '../loading/loading.styles'

const LoadingComponent = ({ mode, loadingref, position, shape, ...props }) => {
  if(shape === 'dot') {
      return (  <LoadingParentContainer mode={mode} ref={loadingref} position={position} {... props} >
                  <div></div>
                  <div></div>
                  <div></div>
                </LoadingParentContainer>  )
  }else {
    return <LoadingContainer mode={mode} ref={loadingref} position={position} {... props} ></LoadingContainer>
  }
}

export default LoadingComponent