import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'

import { debounce, generateMessage } from '../../../assets/javascripts/function'
import moment from '../../../assets/javascripts/moment-range'
import { GET_REPORT_LIST, GET_REPORT_DOWNLOAD } from '../../../config/graphql'

import DateRangeComponent from '../../../components/date/daterange.component'
import LinkComponent from '../../../components/link/link.component'
import TextComponent from '../../../components/text/text.component'

import { FilterMerchantList } from './filter/merchant'
import { ReportingList } from './list/reporting'
import { ReportingContainer, NavContainer, LinkContainer, LayoutNavContainer, LayoutContentContainer, FilterContainer, FilterLabelWrapper, FilterLabel, FilterDownloadWrapper } from '../reporting.styles'

const ReportingPartial = props => {
  let location = useLocation()
  const [ isBusy, setBusy ] = useState(false)
  const [ startDate, setStartDate ] = useState(moment().add(-29, 'days'))
  const [ endDate, setEndDate ] = useState(moment())
  const [ rangeDate, setRangeDate ] = useState('lastmonth')
  const [ filterBy, setFilterBy ] = useState({})

  const { loading, data, fetchMore, networkStatus } = useQuery(GET_REPORT_LIST, 
    {
      variables:
      {
        first: 24,
        filterBy: filterBy,
        startDate: startDate,
        endDate: endDate
      },
      notifyOnNetworkStatusChange: true
    }  
  )
  
  const [getDownload] = useLazyQuery(GET_REPORT_DOWNLOAD, {
    onCompleted(dataDownload) {
      let reportsDownload = dataDownload.reportsDownload
      if(Object.keys(reportsDownload).length) {
        const _success_props = generateMessage('success', 'Download sales order sent to '+reportsDownload.email)
        props.evCreateMessage(_success_props)
      } else {
        const _failed_props = generateMessage('failed', 'Download sales order failed to send. Please try again')
        props.evCreateMessage(_failed_props)
      }
    }
  })

  const evModDate = (startDate, endDate, rangeDate, callback) => {
    setStartDate(startDate)
    setEndDate(endDate)
    setRangeDate(rangeDate)
    if (callback && typeof(callback) == 'function') callback()
  }

  const handleChangeFilter = (e) => {    
    let _finalFilter = {...filterBy}
    
    if(e.value !== '') 
    _finalFilter[e.type] = e.value
    else
    delete _finalFilter[e.type]

    setFilterBy(_finalFilter)
  }

  const handleDownload = (e, merchant) => {
    e.preventDefault()
    getDownload({
      variables:
        {
          filterBy: merchant,
          startDate: startDate,
          endDate: endDate
        }
    })
  }

  const handleScroll = debounce((currentTarget, onLoadMore, isBusy, pageInfo) => {
    if(isBusy || !pageInfo.hasNextPage ) return false
    if(currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight) onLoadMore()
  }, 250)

  const onLoadMore = () => {
    if(isBusy) return false
    setBusy(true)
    fetchMore({
      variables: {
        cursor: data.reports.pageInfo.endCursor
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if(!fetchMoreResult) return prev
        const newEdges = fetchMoreResult.reports.edges
        const pageInfo = fetchMoreResult.reports.pageInfo
        setBusy(false)
        const newData = newEdges.length
          ? {
              reports: {
                totalPageCount: prev.reports.totalPageCount,
                totalCount: prev.reports.totalCount,
                __typename: prev.reports.__typename,
                edges: [...prev.reports.edges, ...newEdges],
                pageInfo
              }
            }
          : prev
        return newData
      }
    })
  }
  
  return (
    <ReportingContainer>
      <LayoutNavContainer>
        <NavContainer>
          <LinkContainer to='/dashboard/orders' isactive={location.pathname === '/dashboard/orders' ? 1 : 0}>Orders</LinkContainer>
          <LinkContainer to='/dashboard/reporting' isactive={location.pathname === '/dashboard/reporting' ? 1 : 0}>Reporting</LinkContainer>
        </NavContainer>
      </LayoutNavContainer>
      <LayoutContentContainer onScroll={(e) => handleScroll(e.currentTarget, onLoadMore, isBusy, data.reports.pageInfo)}>
        <FilterContainer>
          <FilterLabelWrapper>
            <FilterLabel>Date Range</FilterLabel>
            <DateRangeComponent id='select-date-range' StartDate={startDate} EndDate={endDate} RangeDate={rangeDate} evModDate={evModDate}/>
          </FilterLabelWrapper>
          <FilterLabelWrapper>
            <FilterLabel>Merchant</FilterLabel>
            <FilterMerchantList id='select-merchant' handleChange={handleChangeFilter}/>
          </FilterLabelWrapper>
          <FilterDownloadWrapper>
            <FilterLabel></FilterLabel>
            <TextComponent weight='600'>
              <LinkComponent id='link-download-so-all' to='' mode='clearlink' onClick={(e) => handleDownload(e, {})}>Download Sales Order</LinkComponent>
            </TextComponent>
          </FilterDownloadWrapper>
        </FilterContainer>
        <ReportingList 
          loading={loading}
          reports={data && data.reports}
          netWorkStatus={networkStatus}
          isBusy={isBusy}
          handleDownload={handleDownload}
        />
      </LayoutContentContainer>
    </ReportingContainer>
  )
}

export default ReportingPartial