import styled, { css } from 'styled-components'

/* styles */
const elipsisStyle = css `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 100%;
  width: auto;
`
const icon = css `
  i {
    display: inline-block;
    margin-left: 5px;
  }
`

const getTextModifiedStyled = ({mode, color, weight, transform, decoration, elipsis}) => {
  let StyleText = ``
  if(color === 'green') StyleText += `color: green;`
  else if(color === 'red') StyleText += `color: #dd2926;`
  else if(color === 'grey') StyleText += `color: #a9a9a9;`
  else if(color === 'darkgrey') StyleText += `color: #808080;`
  else if(color === 'blue') StyleText += `color: #429ddf;`
  else if(color ===  'orange') StyleText += `color: orange;`

  if(weight)  StyleText += `font-weight: ${weight};`
  if(transform)  StyleText += `text-transform: ${transform};`
  if(decoration)  StyleText += `text-decoration: ${decoration};`

  if(elipsis) StyleText += `${elipsisStyle}`

  return StyleText;
}

/* component */
export const TextContainer = styled.span`
  display: grid;
  ${getTextModifiedStyled}
  ${icon}
`
