import gql from 'graphql-tag'

export const GET_ORDER_LIST = gql`
  query orders($first: Int, $cursor: String, $filterBy: OrderFilterByInputs, $search: String, $startDate: String, $endDate: String) {
    orders(first: $first, after:$cursor, filterBy: $filterBy, search: $search, startDate: $startDate, endDate: $endDate) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          orderedAt
          id
          localId
          profileName
          customer {
            id
            name
          }
          couriers
          awb
          statuses 
        }
      }
    }
  }
`

export const GET_ORDER_DETAIL = gql`
  query order($id: ID){
    order(id: $id) {
      id
      localId
      orderedAt
      stockLocation
      payment
      awb
      invoiceNumber
      price {
          subTotal
          discount
          rebate
          shipping
          insurance
          tax
          total
      }
      shippingServices
      shippingType
      statuses
      notes
      availableAction {
        setPaid
        printPicklist
        printShippinglabel
        printManifest
        readyToShip
        cancel
      }
      itemLines {
        id
          image
          name
          nameVariant
          sku
          status
          quantity
          price
          salePrice
      }
      customer {
        id
        name
        email
      }
      address {
        id
        name
        phone
        fullAddress
      }
    }
  }
`

export const GET_ORDER_FILTER = gql`
  query ordersFilter($type: String!){
    ordersFilter(type: $type) {
      name
      value
    }
  }
`

export const GET_REPORT_LIST = gql`
  query reports($first: Int, $cursor: String, $filterBy: ReportFilterByInputs, $startDate: String, $endDate: String){
    reports(first: $first, after: $cursor, filterBy: $filterBy, startDate: $startDate, endDate: $endDate){
      edges {
        cursor
        node {
          accountId
          ordersCount
          profileName
          revenue
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      totalPageCount
    }
  }
`

export const GET_REPORT_DOWNLOAD = gql`
  query reportsDownload($filterBy: ReportFilterByInputs, $startDate: String, $endDate: String){
    reportsDownload(filterBy: $filterBy, startDate: $startDate, endDate: $endDate){
      url
      email
    }
  }
`