import styled, { css } from 'styled-components'
import LogoForstok from '../../assets/images/logos/forstok-white.png'
import '../../assets/stylesheets/font.css'

/* style */
const BackgroundContainerStyles = css`
  height: 100vh;
`
const LoginContainerStyles = css`
  display: grid;
  grid-template-columns: 1fr;
  font-family: 'Proxima Nova';
  width: 100%;
`;
const SideContainerStyles = css`
  padding: 25px 35px;
  width: 100%;
  min-height: 75px;
  background-color: #ee4e42;
`
const LogoContainerStyles = css`
  &:before {
    position: absolute;
    content: "";
    background-image: url(${LogoForstok});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 150px;
    height: 23px;
  }
`
const LoginFormContainerStyles = css`
  padding: 10px 35px;
  background-color: #fff;
`
const RememberContainerStyles = css`
  margin: 12px 0;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
`
const CopyContainerStyles = css`
  margin-top: 10px;
  padding: 10px 35px;
  text-align: center;
  line-height: 24px;
`
const ErrorContainerStyles = css`
  margin: 10px 0;
  text-align: center;
  color: red;
`

/* style */

/* component */
export const BackgroundContainer = styled.div`
  ${BackgroundContainerStyles}
  @media (min-width: 768px) {
    display: grid;
    justify-items: center;
    align-items: center;
    background-color: #f7f7f7;
  }
`
export const LoginContainer = styled.div`
  ${LoginContainerStyles}
  @media (min-width: 768px) {
    max-width: 800px;
    grid-template-columns: 1.5fr 2fr; 
  }
`
export const SideContainer = styled.div`
  ${SideContainerStyles}
`
export const LogoContainer = styled.span`
  ${LogoContainerStyles}
`
export const LoginFormContainer = styled.form`
  ${LoginFormContainerStyles}
  @media (min-width: 768px) {
    padding: 65px 45px;
  }
`
export const RememberContainer = styled.div`
  ${RememberContainerStyles}
`
export const CopyContainer = styled.div`
  ${CopyContainerStyles}
  @media (min-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`
export const ErrorContainer = styled.div`
  ${ErrorContainerStyles}
`

/* component */