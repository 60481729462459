import React, { Component } from 'react'

import DateRangePicker from 'react-daterange-picker'

import moment from '../../assets/javascripts/moment-range'

import { DateRangeContainer, DateRangeControl, DateRangeLabelContainer, DateRangeLabel, DateRangeIndicatorsContainer, DateRangeIndicatorsArrowIconSvg, DateRangeMenuContainer, DateRangeMenu, DateRangeSelection, SelectionDate } from '../date/date.styles'
import 'react-daterange-picker/dist/css/react-calendar.css'
import './daterange.styles.scss'

class DateRangeComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.ButtonRef = React.createRef()
    this.evToogleDateRangePicker = this.evToogleDateRangePicker.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.setRange = this.setRange.bind(this)
    this.callback = () => {
      this.setState({isOpen: false})
    }
  }

  evToogleDateRangePicker = (e) => {
    const buttonEl = e.target.closest(`.${this.ButtonRef.current.classList[0]}`)
    if (!buttonEl) return
    const containerEl = buttonEl.closest('._refContainer'),
      isOpen = containerEl.classList.contains('is-shown')
    if(!isOpen) {
      const ContainerRef = document.getElementsByClassName('_refContainer is-shown')
      if(ContainerRef.length) {
        for(var i = 0; i < ContainerRef.length; i++){
          ContainerRef[i].classList.remove('is-shown')
        }
      }  
    }
    this.setState({isOpen: !isOpen}, () => {
      if(!isOpen) containerEl.classList.add('is-shown')
      else containerEl.classList.remove('is-shown')
    })
  }

  handleSelect(value) {
    const StartDate = value.start.format('YYYY-MM-DD'),
      EndDate = value.end.format('YYYY-MM-DD')
    this.props.evModDate(StartDate, EndDate, 'custom', this.callback)
  }

  setRange = (e) => {
    const type = e.target.getAttribute('data-type')
    let StartDate = null,
      EndDate = moment().clone().format('YYYY-MM-DD')
    switch(type) {
      case 'today': 
        StartDate= moment().clone().subtract(1, 'days').format('YYYY-MM-DD')
        break
      case 'lastweek':
        StartDate= moment().clone().subtract(7, 'days').format('YYYY-MM-DD')
        break
      case 'lastmonth':
        StartDate= moment().clone().subtract(30, 'days').format('YYYY-MM-DD')
        break
      case 'lastquart':
        StartDate= moment().clone().subtract(90, 'days').format('YYYY-MM-DD')
        break
      case 'custom': 
        EndDate= null
        break
      default: 
        EndDate= null
        break
    }
    this.props.evModDate(StartDate, EndDate, type, this.callback)
  }

  render() {
    const { StartDate, EndDate, RangeDate, id }  = this.props
    const { isOpen } = this.state
    const StartDateMoment = moment(StartDate, 'YYYY-MM-DD'),
      EndDateMoment = moment(EndDate, 'YYYY-MM-DD'),
      OpenClass = isOpen ? 'is-shown' : '' 
    return (
      <DateRangeContainer id={id} className={'_refContainer '+ OpenClass}>
        <DateRangeControl ref={this.ButtonRef} onClick={this.evToogleDateRangePicker} isOpen={isOpen}>
          <DateRangeLabelContainer>
            <DateRangeLabel>
              {StartDate && EndDate ? (StartDateMoment.format('LL') + ' - ' +EndDateMoment.format('LL')): 'Last 30 days'}
            </DateRangeLabel>
          </DateRangeLabelContainer>
          <DateRangeIndicatorsContainer>
            <DateRangeIndicatorsArrowIconSvg height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false'>
              <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
            </DateRangeIndicatorsArrowIconSvg>
          </DateRangeIndicatorsContainer>
        </DateRangeControl>
        {isOpen && (
          <DateRangeMenuContainer>
            <DateRangeMenu>
              <DateRangeSelection>
                <SelectionDate data-type='today' isActive={RangeDate === 'today'} onClick={this.setRange}>Today</SelectionDate>
                <SelectionDate data-type='lastweek' isActive={RangeDate === 'lastweek'} onClick={this.setRange}>Last 7 days</SelectionDate>
                <SelectionDate data-type='lastmonth' isActive={RangeDate === 'lastmonth'} onClick={this.setRange}>Last 30 days</SelectionDate>
                <SelectionDate data-type='lastquart' isActive={RangeDate === 'lastquart'} onClick={this.setRange}>Last 90 days</SelectionDate>
                <SelectionDate data-type='custom' isActive={RangeDate === 'custom'} onClick={this.setRange}>Custom Range</SelectionDate>
              </DateRangeSelection>
              <DateRangePicker
                numberOfCalendars={2}
                onSelect={this.handleSelect}
                selectionType='range'
                maximumDate={new Date()}
                value={StartDate && EndDate ? moment.range(StartDateMoment, EndDateMoment) : moment.range(moment().clone().subtract(30, 'days'), moment().clone())} 
                initialMonth={moment().add(-1, 'month').month()}
                initialYear={moment().add(-1, 'year').year()}
              />
            </DateRangeMenu>
          </DateRangeMenuContainer>
        )}
      </DateRangeContainer>
    )
  }
}

export default DateRangeComponent