import styled, { css } from 'styled-components'

/* style */
const DateRangeWrapperStyles = css`
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  height: 36px;
`
const SelectionDateActiveStyles = css`
  background-color: #fc5c64; 
  color: #ffffff;
`
/* style */

/* function */
const getSelectionDateModifiedStyled = props => {
  if(props.isActive) return SelectionDateActiveStyles
}
/* function */

/* component */
export const DateRangeMenuContainer = styled.section`
  top:100%;
  background-color: #ffffff;
  border-radius:4px;
  box-shadow:0 0 0 1px rgba(0, 0, 0, .1),0 4px 11px rgba(0, 0, 0, .1);
  margin: 8px 0;
  position:absolute;
  z-index:1;
  width: 100%;
  display: none;
  @media (min-width: 768px) {
    width: 760px;
  }
`
export const DateRangeControl = styled.div`
  ${DateRangeWrapperStyles}
  border: 1px solid #cccccc;
  border-radius: 3px;
  cursor: pointer;
  justify-content: space-between;
 
`
export const DateRangeContainer = styled.section`
  position: relative;
  display: inline-grid;
  width: 100%;
  @media (min-width: 768px) {
    width: 305px;
  }
  &.is-shown {
    & ${DateRangeMenuContainer} {
      display: block;
    }
    // & ${DateRangeControl} {
    //   border-color: #fc5c64;
    // }
  }
`
export const DateRangeLabelContainer = styled.div`
  ${DateRangeWrapperStyles}
  padding: 0 8px;
  position: relative;
  overflow: hidden;
`
export const DateRangeLabel = styled.div`
  position: absolute;
  top: 50%;
  max-width: calc(100% - 8px);
  color: initial;
  margin: 0 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform: translateY(-50%);
  padding-bottom: 2px;
  font-weight: 600;
`
export const DateRangeIndicatorsContainer= styled.div`
  color: #757575;
  display: flex;
  padding: 0 8px;
  transition: color 150ms;
`
export const DateRangeIndicatorsArrowIconSvg= styled.svg`
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
`
export const DateRangeMenu = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  @media (min-width: 768px) {
    grid-auto-flow: column;
    grid-template-columns: .2fr 1fr;
  }
`
export const DateRangeSelection = styled.aside`
  display: inline-grid;
  grid-auto-flow: column;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  align-items: start;
  @media (min-width: 768px) {
    grid-auto-flow: row;
    grid-template-rows: repeat(5, 35px [row-start]);
    grid-row-gap: 1px;
    border-right: 1px solid rgba(0, 0, 0, .1);
    border-bottom: 0;
    align-items: center;
  }
`
export const SelectionDate = styled.div`
  cursor: pointer;
  display: grid;
  justify-self: stretch;
  padding: 10px;
  border-right: 1px solid rgba(0, 0, 0, .1);
  height: 100%;
  align-items: center;
  ${getSelectionDateModifiedStyled}
  &:hover {
    background-color: #ec5b62; 
    color: #fff;
  }
  @media (min-width: 768px) {
    height: auto;
    border-right: 0;
  }
`
/* component */
