import React from 'react'
import { useQuery } from "@apollo/react-hooks"

import { GET_ORDER_FILTER } from '../../../../config/graphql'
import LoadingComponent from '../../../../components/loading/loading.component'
import SelectComponent from '../../../../components/select/select.component'

export const FilterStatusList = ({ handleChange, id }) => {
  const { loading, error, data } = useQuery(GET_ORDER_FILTER, { variables: { type: 'status' } })
  if(loading) return <LoadingComponent mode='mediumclear'/>
  if(error) return `Error!: ${error}`
  let DefaultValue = { value: '', label: 'All Status', type: 'status' },
    StatusOptions = data ? data.ordersFilter.map(list => {
      return { value: list.value, label: list.name, type: 'status' }
    }) : []
  StatusOptions = [DefaultValue].concat(StatusOptions)
  return (
    <SelectComponent id={id} plugin='react-select' options={StatusOptions} defaultValue={DefaultValue} onChange={handleChange} />
  )
}