import React from 'react'

import { ImageContainer } from '../image/image.styles'

const ImageComponent = ({ mode, width, ...props }) => {
  return (
    <ImageContainer mode={mode} width={width} {...props}></ImageContainer>
  )
}

export default ImageComponent