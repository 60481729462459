import React, { useState } from 'react'
import { ApolloProvider } from 'react-apollo'
import { client } from '../../_includes/forstok'

import SingleLayout from '../../_layouts/single-layout'
import PopupContainer from '../../components/popup/popup.container'

import OrderPartial from './partials/order'

const OrdersIndex = () => {
  const [ isOpenPopup, setOpenPopup ] = useState(false)
  const [ isOpenPopupContainer, setOpenPopupContainer ] = useState(false)
  const [ modePopup, setModePopup ] = useState('')
  const [ pathPopup, setPathPopup ] = useState('')
  const [ dataIdPopup, setDataIdPopup ] = useState('')

  const evOpenPopup = (e) => {
    const buttonEl = e.target.closest('a')
    if (!buttonEl) return
    const popup = buttonEl.getAttribute('data-popup')
    const dataId = buttonEl.getAttribute('data-id')

    const props = {
      mode: popup,
      id: dataId
    }
    evTooglePopup(props)
  }

  const evTooglePopup = (props) => {
    if(isOpenPopup) {
      setOpenPopup(false)
      setOpenPopupContainer(false)
      document.getElementsByTagName("BODY")[0].classList.remove("is-immuned")
    }else {
      setOpenPopup(true)
      setModePopup(props.mode)
      setDataIdPopup(props.id)
      setPathPopup(props.path)
      document.getElementsByTagName("BODY")[0].classList.add("is-immuned") 
      setOpenPopupContainer(true)
    }
  }

  return (
    <ApolloProvider client={client}>
      <SingleLayout evTooglePopup={evTooglePopup}>
        <OrderPartial evOpenPopup={evOpenPopup} />
        <PopupContainer isOpen={isOpenPopup} isOpenContainer={isOpenPopupContainer} mode={modePopup} dataId={dataIdPopup} path={pathPopup} evTooglePopup={evTooglePopup} />
      </SingleLayout>
    </ApolloProvider>
  )
}

export default OrdersIndex;