export const colorOrderStatus = (value) => {
  if(!value) return ''
  let status = value.toLowerCase(),
    color= 'green'
  switch(status) {
    case "cancelled":
      color = 'darkgray'
      break;
    case "not paid":
      color = 'red'
      break;
    case "pending payment":
      color = 'red'
      break;
    case String(status.match(/^open.*/)):
      color = 'orange'
      break;
    case String(status.match(/^printed.*/)):
      color = 'orange'
      break;
    default:
      color = 'green'
  }
  return color
}
