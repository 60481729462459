export const currencyNumber = value => {
  let newValue = 0
  if(typeof value == 'number'){
    newValue = value.toLocaleString('id-ID', {style: 'currency', currency: 'IDR', minimumFractionDigits: 0})
  }
  return newValue
}

export const debounce = (func, wait, immediate) => {
  let timeout;

  return function executedFunction() {
    let context = this;
    let args = arguments;
	    
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    let callNow = immediate && !timeout;
	
    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
	
    if (callNow) func.apply(context, args);
  };
}

export const generateMessage = (type, value, callback, timer) => {
  let result 

  result = {
    type: type,
    message: value,
    timer: timer || 2500,
  } 

  if (callback && typeof(callback) == "function") result.callback = () => {
    callback()
  }

  return result || {}
}