import React from 'react'

import HeaderInclude from '../_includes/header/header-include'

import { SingleLayoutContainer, SingleLayoutBody, SingleLayoutWrapper } from './layout.styles'

const SingleLayout = ({ children, ...props }) => {
  return (
    <SingleLayoutContainer>
      <HeaderInclude evTooglePopup={props.evTooglePopup}></HeaderInclude>
      <SingleLayoutBody>
        <SingleLayoutWrapper>{children}</SingleLayoutWrapper>
      </SingleLayoutBody>
    </SingleLayoutContainer>
  )
}

export default SingleLayout