import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

/* style */
const DefaultStylesDetailInfo = css`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
`
/* style */

/* function */
const getMenuModifiedStyled = ({isactive, mode}) => {
  let stylesTab = ``
  if(isactive) stylesTab += `
    color: #fc5c64;
    font-weight: 700;

    &::after {
      content: '';
      position: absolute;
      background-color: #fc5c64;
      left: 0;
      height: 2px;
      bottom: -1px;
      right: 0;
      width: auto;
    }
  `
  return stylesTab
}
/* function */

/* component */
export const OrderContainer = styled.div`
  width: 100%;
  height: 100%;
`
export const NavContainer = styled.div`
  display: inline-grid;
  margin: 0;
  padding: 0;
  grid-auto-flow: column;
`
export const LinkContainer = styled(Link)`
  position: relative;
  padding: 15px 30px;
  font-weight: 400;
  color: #2c2c2c;
  text-align: center;
  list-style: none;
  cursor: pointer;
  user-select: none;
  font-size: 20px;
  text-decoration: none;
  ${getMenuModifiedStyled}
`
export const LayoutNavContainer = styled.div`
  width: 100%;
  background-color: #fff;
`
export const LayoutContentContainer = styled.div`
  margin-top: 5px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  overflow: auto;
  height: calc(100vh - 120px);
`
export const FilterContainer = styled.div`
  display: grid;
  margin: 10px 0 20px;
  grid-auto-flow: column;
  grid-template-columns: 305px 200px 200px 300px;
  grid-gap: 10px;
`
export const FilterLabelWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 10px;
`
export const FilterLabel = styled.div`
  font-weight: 600;
`
export const FilterSearchContainer = styled.div`
  position: relative;
  display: grid;
  align-items: end;

  > i {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 1;
  }
`
export const DetailTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const DetailSubTitle = styled.div`
  grid-auto-columns: max-content;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
`
export const OrderDetailContainer = styled.div`
  padding: 20px 30px;
` 
export const DetailInfoContainer = styled.section`
  ${DefaultStylesDetailInfo}
  margin-top: 15px;
  grid-template-columns: 1fr 1fr;
`
export const DetailInfoWrapper = styled.article`
  color: #2c2c2c;
`
export const DetailInfoList = styled.div`
  display: inline-grid;
  grid-auto-flow: row;
  grid-gap: 6px;
  width: 100%;
`
export const DetailInfoItem = styled.div`
  ${DefaultStylesDetailInfo}
  grid-template-columns: 0.5fr 1fr;
  line-height: 18px;
  span:first-child {
    position: relative;
    &:after {
      content: ':';
      position: absolute; 
      right: 3px;
      top: 0;
    }
  }
`
export const TableOrderItemContainer = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr;
  align-items: center;
  font-weight: 600;

  > img {
    grid-row-start: 1;
    grid-row-end: 3;
  }
`
export const TableOrderItemDetail = styled.div`
  color: #808080;
  > span {
    font-weight: 700;
  }
`
export const TableOrderPriceContainer = styled.div`
  display: grid;
  grid-template-columns: 10px auto;
  justify-items: right;
  align-items: center;
  grid-gap: 10px;
`
export const TableOrderPriceInitial = styled.span`
  color: gray;
`
export const TableOrderPriceAmount = styled.span`
  
`
/* component */