import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { Message, MessageOverlay, MessageContainer, MessageWrapper, MessageContent } from './message.styles'

import ButtonComponent from '../button/button.component'

class MessageComponent extends Component {
  static propTypes = {
    message: PropTypes.node.isRequired, 
    type: PropTypes.string.isRequired, 
    timer: PropTypes.number,
    callback: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isOpenContainer: false
    }
    this.evCloseMessage = this.evCloseMessage.bind(this)
  }
  
  componentDidMount() {
    const timer = this.props.timer ? this.props.timer : 5000
    this.setState({ isOpen: !this.state.isOpen }, ()=> {
      setTimeout(() => {
        this.setState({isOpenContainer:!this.state.isOpenContainer} , () => {
          setTimeout(() => {
            if(this.state.isOpenContainer) this.evCloseMessage()
          }, timer)
        })
      }, 1)
    })
  }

  evCloseMessage = () => {
    this.setState({isOpenContainer:!this.state.isOpenContainer, isOpen:!this.state.isOpen}, () => {
      const bodyEl = document.getElementsByTagName("BODY")[0]
      bodyEl.classList.remove("is-immuned")
      ReactDOM.unmountComponentAtNode(document.getElementById('message'))
      if (this.props.callback && typeof(this.props.callback) == "function") this.props.callback()
    })
  }

  render() {
    const { type, message } = this.props
    const { isOpen, isOpenContainer } = this.state
    return (
      <Message isOpen={isOpen}>
        <MessageOverlay></MessageOverlay>
        <MessageContainer>
          <MessageWrapper isOpen={isOpenContainer}>
            <MessageContent type={type}>
              <i></i>
              {message}
              <ButtonComponent mode='smallwhiteroundclose' onClick={this.evCloseMessage}></ButtonComponent>
            </MessageContent>
          </MessageWrapper>
        </MessageContainer>
      </Message>
    )
  }
}

MessageComponent.defaultProps = {
  timer: 500
}

export default MessageComponent