import React from 'react'
import { useQuery } from '@apollo/react-hooks'

import moment from '../../../assets/javascripts/moment-range'
import { currencyNumber } from '../../../assets/javascripts/function'
import { colorOrderStatus } from '../../../assets/javascripts/helper'
import { GET_ORDER_DETAIL } from '../../../config/graphql'

import PopupComponent from '../../../components/popup/popup.component'
import TableComponent from '../../../components/table/table.component'
import LoadingComponent from '../../../components/loading/loading.component'
import LabelComponent from '../../../components/label/label.component'
import TextComponent from '../../../components/text/text.component'
import ImageComponent from '../../../components/image/image.component'

import { DetailTitle, DetailSubTitle, OrderDetailContainer, DetailInfoContainer, DetailInfoWrapper, DetailInfoList, DetailInfoItem, TableOrderItemContainer, TableOrderItemDetail, TableOrderPriceContainer, TableOrderPriceInitial, TableOrderPriceAmount } from '../orders.styles'

export const PopupDetailOrder = ({ body, height, width, isOpen, isOpenContainer, evTooglePopup, dataId }) => {
  const { loading, error, data } = useQuery(GET_ORDER_DETAIL, { variables: { id: dataId } })
  if(error) return `Error!: ${error}`
  return (
    <PopupComponent body={body} height={height} width={width} isOpen={isOpen} isOpenContainer={isOpenContainer} evTooglePopup={evTooglePopup}>
      <div aria-label='head'>Order Details</div>
      <div aria-label='body'>
        {
          (loading) ? <OrderDetailContainer>
            <LoadingComponent mode='mediumclear'/>
          </OrderDetailContainer>
          : 
          <OrderDetailContainer>
            <DetailTitle>
              #SO-{data.order.id}
            </DetailTitle>
            <DetailSubTitle>
              <TextComponent color='grey'>{data.order.shippingType.length ? '' : ' - '}{data.order.shippingType.join(', ')}</TextComponent> 
              <TextComponent color={colorOrderStatus(data.order.statuses[0])} weight='600'>{data.order.statuses[0]}</TextComponent>
            </DetailSubTitle>
            <DetailInfoContainer>
              <DetailInfoWrapper>
                <DetailInfoList>
                  <DetailInfoItem>
                    <TextComponent color='darkgrey'>Order Date</TextComponent>
                    <TextComponent elipsis={false}>{moment(data.order.orderedAt, 'YYYY-MM-DD HH:mm:ss ZZ').format('DD MMMM YYYY, h:mm a')}</TextComponent>
                  </DetailInfoItem>
                  <DetailInfoItem>
                    <TextComponent color='darkgrey'>Order ID</TextComponent>
                    <TextComponent elipsis={false}>{data.order.id}</TextComponent>
                  </DetailInfoItem>
                  <DetailInfoItem>
                    <TextComponent color='darkgrey'>Channel Order ID</TextComponent>
                    <TextComponent elipsis={false}>{data.order.localId}</TextComponent>
                  </DetailInfoItem>
                  <DetailInfoItem>
                    <TextComponent color='darkgrey'>Courier</TextComponent>
                    <TextComponent elipsis={false}>{data.order.shippingType.length ? '': ' - '}{data.order.shippingType.join(', ')}</TextComponent>
                  </DetailInfoItem>
                  <DetailInfoItem>
                    <TextComponent color='darkgrey'>AWB</TextComponent>
                    <TextComponent elipsis={false} color='blue' weight='600'>{data.order.awb}</TextComponent>
                  </DetailInfoItem>
                </DetailInfoList>
              </DetailInfoWrapper>
              <DetailInfoWrapper>
                <DetailInfoList>
                  <DetailInfoItem>
                    <TextComponent color='darkgrey'>Name</TextComponent>
                    <TextComponent elipsis={false}>{data.order.customer.name}</TextComponent>
                  </DetailInfoItem>
                  <DetailInfoItem>
                    <TextComponent color='darkgrey'>Email</TextComponent>
                    <TextComponent elipsis={false}>{data.order.customer && data.order.customer.email ? data.order.customer.email  : '-'}</TextComponent>
                  </DetailInfoItem>
                  <DetailInfoItem>
                    <TextComponent color='darkgrey'>Address</TextComponent>
                    <TextComponent elipsis={false}>
                      {
                        data.order.address ?  (<div>
                          {data.order.address && data.order.address.name }<br/>
                          {data.order.address && data.order.address.fullAddress}<br/>
                          {data.order.address && data.order.address.phone}
                        </div>) : '-'
                      }
                    </TextComponent>
                  </DetailInfoItem>
                </DetailInfoList>  
              </DetailInfoWrapper>
            </DetailInfoContainer>
            <TableComponent mode='custom' template='6fr 1.5fr 1.5fr'>
              <div aria-label='head'>
                <div>Items</div>
                <div></div>
                <div>Amount</div>
              </div>
              <div aria-label='body'>
                {data.order.itemLines.map((item, idx) => {
                  return (
                    <div key={idx}>
                      <div>
                        <LabelComponent mode='tagredmobile'>Items</LabelComponent>
                        <TableOrderItemContainer>
                          <ImageComponent mode='product' src={item.image} width='30px'/>
                          {item.name}
                          <TableOrderItemDetail>
                            <TextComponent color='grey'>SKU: {item.sku}</TextComponent>
                          </TableOrderItemDetail>
                        </TableOrderItemContainer>
                      </div>  
                      <div>
                        <LabelComponent mode='tagredmobile'>Status</LabelComponent>
                        <TextComponent color={colorOrderStatus(item.status)} weight='600'>{item.status}</TextComponent>
                      </div>
                      <div>
                        <LabelComponent mode='tagredmobile'>Amount</LabelComponent>
                        <TableOrderPriceContainer>
                          <TableOrderPriceInitial>{item.quantity}x</TableOrderPriceInitial>
                          {
                            item.salePrice ?
                            <TableOrderPriceAmount>
                              <TextComponent color='grey' decoration='line-through'>{currencyNumber(item.price)}</TextComponent>
                              <TextComponent>{currencyNumber(parseInt(item.salePrice))}</TextComponent>
                            </TableOrderPriceAmount> 
                            : 
                            <TableOrderPriceAmount>
                              <TextComponent>{currencyNumber(item.price)}</TextComponent>
                            </TableOrderPriceAmount>
                          }
                        </TableOrderPriceContainer>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div aria-label='foot'>
                <div> 
                  <div></div>
                  <div>
                    <TextComponent weight='600' transform='uppercase'>Subtotal</TextComponent>
                  </div>
                  <div>
                    <TableOrderPriceContainer>
                      <TableOrderPriceInitial> </TableOrderPriceInitial>
                      <TableOrderPriceAmount>
                        <TextComponent weight='600'>{currencyNumber(data.order.price.subTotal)}</TextComponent>
                      </TableOrderPriceAmount>
                    </TableOrderPriceContainer>
                  </div>
                </div>
                {
                  data.order.price.discount > 0 ?
                  <div> 
                    <div></div>
                    <div>
                      <TextComponent color='darkgrey'>Discount</TextComponent>
                    </div>
                    <div>
                      <TableOrderPriceContainer>
                        <TableOrderPriceInitial>-</TableOrderPriceInitial>
                        <TableOrderPriceAmount>
                          <TextComponent color='darkgrey'>{currencyNumber(data.order.price.discount)}</TextComponent>
                        </TableOrderPriceAmount>
                      </TableOrderPriceContainer>
                    </div>
                  </div>
                  : null
                }
                {
                  data.order.price.rebate > 0 ?
                  <div> 
                    <div></div>
                    <div>
                      <TextComponent color='darkgrey'>Rebate</TextComponent>
                    </div>
                    <div>
                      <TableOrderPriceContainer>
                        <TableOrderPriceInitial>+</TableOrderPriceInitial>
                        <TableOrderPriceAmount>
                          <TextComponent color='darkgrey'>{currencyNumber(data.order.price.rebate)}</TextComponent>
                        </TableOrderPriceAmount>
                      </TableOrderPriceContainer>
                    </div>
                  </div>
                  : null
                }
                <div> 
                  <div></div>
                  <div>
                    <TextComponent color='darkgrey'>Shipping</TextComponent>
                  </div>
                  <div>
                    <TableOrderPriceContainer>
                      <TableOrderPriceInitial>+</TableOrderPriceInitial>
                      <TableOrderPriceAmount>
                        <TextComponent color='darkgrey'>{currencyNumber(data.order.price.shipping)}</TextComponent>
                      </TableOrderPriceAmount>
                    </TableOrderPriceContainer>
                  </div>
                </div>
                {
                  data.order.price.insurance > 0 ?
                  <div> 
                    <div></div>
                    <div>
                      <TextComponent color='darkgrey'>Insurance</TextComponent>
                    </div>
                    <div>
                      <TableOrderPriceContainer>
                        <TableOrderPriceInitial>+</TableOrderPriceInitial>
                        <TableOrderPriceAmount>
                          <TextComponent color='darkgrey'>{currencyNumber(data.order.price.insurance)}</TextComponent>
                        </TableOrderPriceAmount>
                      </TableOrderPriceContainer>
                    </div>
                  </div>
                  : null 
                }
              </div>
              <div aria-label='final'>
                <div> 
                  <div></div>
                  <div>
                    <TextComponent weight='700' transform='uppercase'>Total</TextComponent>
                  </div>
                  <div>
                    <TableOrderPriceContainer>
                      <TableOrderPriceInitial> </TableOrderPriceInitial>
                      <TableOrderPriceAmount>
                        <TextComponent weight='700'>{currencyNumber(data.order.price.total)}</TextComponent>
                      </TableOrderPriceAmount>
                    </TableOrderPriceContainer>
                  </div>
                </div>
              </div>
            </TableComponent>
          </OrderDetailContainer>
        }
      </div>
    </PopupComponent>
  )
}