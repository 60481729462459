import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import LogoForstokSmall from '../../assets/images/logos/forstok-small.png'
import LogoForstok from '../../assets/images/logos/forstok.png'

/* style */
const NavContainerStyles = css`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  @media (min-width: 360px) {
    grid-gap: 10px;
  }
`
/* style */

/* component */
export const HeaderContainer = styled.section`
  position: fixed;
  z-index: 99;
  display: grid;
  grid-template-columns: .5fr 1fr 1fr;
  grid-area: header;
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid rgba(0,0,0,.05);
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
  @media (min-width: 768px) {
    grid-template-columns: 125px 1fr 1fr;
  }
`
export const LogoContainer = styled(Link)`
  width: 36px;
  height: 27px;
  &:before {
    position: absolute;
    content: '';
    top: 22px;
    background-image: url(${LogoForstokSmall});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: -12px;
    width: 36px;
    height: 36px;
  }
  @media (min-width: 768px) {
    width: 110px;
    &:before {
      background-image: url(${LogoForstok});
      height: 48px;
      width: 110px;
      top: 16px;
    }
  }
`
export const TextContainer = styled.div`
  display: grid;
  font-weight: 400;
  color: #2c2c2c;
  font-size: 22px;
  align-items: center
`
export const SideNavContainer = styled.nav`
  ${NavContainerStyles}
  justify-content: end;
  align-items: center;
  width: 100%;

  > a {
    font-weight: 700;
  }
`
/* component */