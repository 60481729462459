import styled, { css } from 'styled-components'
import IconCheck from '../../assets/images/icons/check-white.svg'
import IconWarning from '../../assets/images/icons/warning.svg'
import IconWarningWhite from '../../assets/images/icons/warning-white.svg'

/* style */
const RightButtonStyles = css`
  button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
`
const AsideButtonStyles = css`
  aside {
    display: grid;
    grid-auto-flow: column;
    width: 100%;
    justify-content: end;
  }
`

/* function */
const getMessageModifiedStyled = (props) => {
  if(props.isOpen) return `
    & ${MessageOverlay}, & ${MessageContainer} {
      display: block;
    }
  `
}
const getMessageWrapperModifiedStyled = (props) => {
  if(props.isOpen) return `
    opacity: 1;
  `
}
const getMessageContentModifiedStyled = ({type}) => {
  let stylesMessage = ''
  switch(type){
    case 'success' : stylesMessage += `
      background-color: #429ddf;
      i {
        border: 2px solid #fff;
        border-radius: 50%;
        &:before { 
          width: .75em;
          height: 1em;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: url(${IconCheck}); 
        }
      }
      ${RightButtonStyles}
    `
    break
    case 'failed' : stylesMessage += `
      background-color: #dd2926;
      i {
        &:before { 
          width: 1.5em;
          height: 1em;
          top: 0;
          left: 0;
          content: url(${IconWarningWhite}); 
        }
      }
      ${RightButtonStyles}
    `
    break
    case 'info' : stylesMessage += `
     
    `
    break
    case 'warning' : stylesMessage += `
      background-color: #e1ad01;
      i {
        &:before { 
          width: 1.5em;
          height: 1em;
          top: 0;
          left: 0;
          content: url(${IconWarningWhite}); 
        }
      }
      ${RightButtonStyles}
    `
    break
    case 'confirm' : stylesMessage += `
      background-color: #f7f7f7;
      i {
        align-self: start;
        &:before { 
          width: 21px;
          height: 21px;
          content: url(${IconWarning}); 
        }
      }
      span {
        display: block;
        margin-bottom: 5px;
      }
      ${AsideButtonStyles}
    `
    break
    default:
      
    break
  }
  return stylesMessage
}
/* function */

/* component */
export const Message = styled.div`
  // font-family: Proxima Nova, sans-serif;
  ${getMessageModifiedStyled}
`
export const MessageOverlay = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  overflow: hidden;
  background-color: rgba(0,0,0,.25);
`
export const MessageContainer = styled.section`
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 5%;
  left: 50%;
  z-index: 105;
  transform: translate(-50%);
`
export const MessageWrapper = styled.section`
  max-width: 320px;
  line-height: 16px;
  height: auto;
  max-height: 100%;
  transform: translate(-50%);
  left: 50%;
  top: 0;
  opacity: 0;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.22);
  background-color: #fff;
  border-radius: 2px;
  overflow: hidden;
  transition: opacity .4s;
  width: auto;
  min-width: 600px;
  padding: 0;
  position: relative;
  display: inline-grid;
  grid-auto-flow: row;
  ${getMessageWrapperModifiedStyled}
`

export const MessageContent = styled.section`
  width: 100%;
  display: inline-grid;
  grid-auto-flow: column;
  padding: 15px 35px 15px 15px;
  font-weight: 400;
  vertical-align: middle;
  align-items: center;
  grid-gap: 10px;
  justify-content: start;
  color: #fff;
  ${getMessageContentModifiedStyled}
  i {
    float: right;
    position: relative;
    width: 21px;
    height: 21px;
    &::before {
      position: absolute;
    }
  }
`
/* component */